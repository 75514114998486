interface IProps {}

export const QuestionIcon = ({}: IProps) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-black dark:fill-white"
        >
            <path
                d="M8 0C3.58603 0 0 3.58603 0 8C0 12.414 3.58603 16 8 16C12.414 16 16 12.414 16 8C16 3.58603 12.414 0 8 0ZM8 0.727273C12.0209 0.727273 15.2727 3.97908 15.2727 8C15.2727 12.0209 12.0209 15.2727 8 15.2727C3.97908 15.2727 0.727273 12.0209 0.727273 8C0.727273 3.97908 3.97908 0.727273 8 0.727273ZM8 3.63636C6.59849 3.63636 5.45455 4.78031 5.45455 6.18182C5.45387 6.23001 5.46277 6.27785 5.48074 6.32256C5.49871 6.36728 5.52538 6.40798 5.55922 6.44229C5.59305 6.47661 5.63337 6.50386 5.67782 6.52246C5.72228 6.54106 5.76999 6.55064 5.81818 6.55064C5.86637 6.55064 5.91408 6.54106 5.95854 6.52246C6.003 6.50386 6.04332 6.47661 6.07715 6.44229C6.11099 6.40798 6.13766 6.36728 6.15563 6.32256C6.1736 6.27785 6.1825 6.23001 6.18182 6.18182C6.18182 5.17336 6.99154 4.36364 8 4.36364C9.00846 4.36364 9.81818 5.17336 9.81818 6.18182C9.82528 6.9985 9.3868 7.43818 8.84659 7.94887C8.57649 8.20419 8.28778 8.4647 8.05114 8.78977C7.8145 9.11483 7.63637 9.52006 7.63637 10V10.1818C7.63568 10.23 7.64459 10.2778 7.66256 10.3226C7.68052 10.3673 7.7072 10.408 7.74103 10.4423C7.77487 10.4766 7.81519 10.5039 7.85964 10.5225C7.9041 10.5411 7.95181 10.5506 8 10.5506C8.04819 10.5506 8.0959 10.5411 8.14036 10.5225C8.18482 10.5039 8.22513 10.4766 8.25897 10.4423C8.2928 10.408 8.31948 10.3673 8.33745 10.3226C8.35542 10.2778 8.36432 10.23 8.36364 10.1818V10C8.36364 9.68837 8.46132 9.45637 8.63637 9.21591C8.81141 8.97546 9.06917 8.73955 9.34659 8.47727C9.90042 7.95371 10.5525 7.27394 10.5455 6.18182C10.5454 6.17978 10.5455 6.17816 10.5455 6.17614C10.5423 4.77726 9.39958 3.63636 8 3.63636ZM8 11.2727C7.69876 11.2727 7.45455 11.5169 7.45455 11.8182C7.45455 12.1194 7.69876 12.3636 8 12.3636C8.30125 12.3636 8.54546 12.1194 8.54546 11.8182C8.54546 11.5169 8.30125 11.2727 8 11.2727Z"
            />
        </svg>
    );
};
