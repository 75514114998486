import { HTMLAttributes } from "react";

export interface IRadioProps extends HTMLAttributes<HTMLDivElement> {
    value: boolean;
    isDisabled?: boolean;
    label?: string;
    handleClick: () => void;
}

export const Radio = ({ value, label, isDisabled = false, handleClick, className, ...props }: IRadioProps) => {
    return (
        <div
            className={`w-full cursor-pointer flex items-center gap-4 ${className}`}
            onClick={(e) => {
                e.stopPropagation();
                if (isDisabled) return;
                handleClick();
            }}
            {...props}
        >
            <div
                className={`flex items-center justify-center w-5 h-5 border ${
                    isDisabled ? "border-irGray-600" : "border-irBlue-200 dark:border-darkPrimary-200"
                }  rounded-full flex-shrink-0`}
            >
                {value && (
                    <div
                        className={`rounded-full ${
                            isDisabled ? "bg-irGray-600" : "bg-irBlue-200 dark:bg-darkPrimary-200"
                        }`}
                        style={{ height: "10px", width: "10px" }}
                    />
                )}
            </div>

            {label && <div className={`${isDisabled ? "text-irGray-600" : ""}`}>{label}</div>}
        </div>
    );
};
