import { useToggle } from "client/hooks/utils/useToggle";
import { getImageExtension } from "client/utils/getImageExtension";
import { settingsSetMaxFileSize, settingsSetQuality, settingsSetType } from "store/features/compress/actions";
import { compressSelector } from "store/features/compress/selectors/compressSelector";
import { CompressState } from "store/features/compress/types";
import { useAppDispatch, useAppSelector } from "store/store";
import { useRouter } from "next/router";
import { RouteNames } from "@/RouteNames";
import { useEffect } from "react";

export const useSettings = () => {
    const dispatch = useAppDispatch();
    const {
        compressionSettings: { maxFileSize, quality, type },
    } = useAppSelector(compressSelector);
    const { images } = useAppSelector((state) => state.imageUploader);
    const { isOpen, toggle } = useToggle();
    const { pathname } = useRouter();
    const handleMaxFileSizeChange = (size: number) => dispatch(settingsSetMaxFileSize(size));
    const handleQualityChange = (quality: number) => dispatch(settingsSetQuality(quality));
    const handleTypeChange = (type: CompressState["compressionSettings"]["type"]) => dispatch(settingsSetType(type));

    const isNonJpegImageSelected =
        images.length > 0 &&
        !images.some(
            (image) =>
                getImageExtension(image.file.file).toLocaleLowerCase() === "jpg" ||
                getImageExtension(image.file.file).toLocaleLowerCase() === "jpeg",
        );
    const shouldHideMaxFileSize = pathname !== RouteNames.imageCompressor && pathname !== RouteNames.jpegCompressor;
    const isMaxFileSizeDisabled =
        pathname === RouteNames.imageCompressor || pathname === RouteNames.jpegCompressor
            ? isNonJpegImageSelected
            : true;
    const isQualityDisabled = false;

    useEffect(() => {
        if (isMaxFileSizeDisabled) {
            handleTypeChange("quality");
        }
    }, [isMaxFileSizeDisabled]);

    return {
        maxFileSize,
        quality,
        type,
        handleMaxFileSizeChange,
        handleQualityChange,
        handleTypeChange,
        isOpen,
        toggle,
        isMaxFileSizeDisabled,
        shouldHideMaxFileSize,
        isQualityDisabled,
    };
};
