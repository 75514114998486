import { extensionsConfig } from "client/config/extensionsConfig";
import { ImageUploaderProps } from "components/common/Uploader/ImageUploader/ImageUploader";

export const getToolName = (pathname: string) => pathname.split("/")[1];

export const getHtmlFileInputId = (pathname: string) => {
    switch (getToolName(pathname)) {
        case "image-compressor":
            return "compressor-file-input";
        case "compress-jpeg":
            return "compress-jpeg-file-input";
        case "png-compressor":
            return "compress-png-file-input";
        case "gif-compressor":
            return "compress-gif-file-input";
        default:
            throw new Error("no tool found");
    }
};

export const getAnalytics = (pathname: string): ImageUploaderProps["analytics"] => {
    switch (getToolName(pathname)) {
        case "image-compressor":
            return {
                pageKey: "image_compressor",
                pageTitle: "Image Compressor",
            };
        case "compress-jpeg":
            return {
                pageKey: "compress_jpeg",
                pageTitle: "Compress Jpeg",
            };
        case "png-compressor":
            return {
                pageKey: "png_compressor",
                pageTitle: "PNG Compressor",
            };
        case "gif-compressor":
            return {
                pageKey: "gif_compressor",
                pageTitle: "GIF Compressor",
            };
        default:
            throw new Error("no tool found");
    }
};

export const getSupportedExtensions = (pathname: string): ImageUploaderProps["supportedExtensions"] => {
    switch (getToolName(pathname)) {
        case "image-compressor":
            return extensionsConfig["image-compressor"];
        case "compress-jpeg":
            return extensionsConfig["compress-jpeg"];
        case "png-compressor":
            return extensionsConfig["png-compressor"];
        case "gif-compressor":
            return extensionsConfig["gif-compressor"];
        default:
            throw new Error("no tool found");
    }
};
