import { FileState, ImageUpload } from "store/features/imageUploader/types";

export const MAX_UPLOAD_LIMIT = 50;

export const uploadingStatuses: FileState["status"][] = [
    "added",
    "creating_task",
    "creating_task_failed",
    "importing",
    "importing_failed",
    "task_created",
];

export const isUploadComplete = (images: ImageUpload[]): boolean => {
    if (images.length === 0) return false;

    let isUploading = false;

    images.forEach((image) => {
        if (uploadingStatuses.includes(image.fileState.status)) {
            isUploading = true;
        }
    });
    return !isUploading;
};
