import { DetailedHTMLProps, InputHTMLAttributes } from "react";
import { useInput } from "./useInput";
import { twMerge } from "tailwind-merge";

export interface InputProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    shouldSelect?: boolean;
    acceptFloat?: boolean;
    isDisabled?: boolean;
}

export const Input = ({
    className,
    onChange,
    shouldSelect = true,
    value,
    defaultValue,
    type,
    acceptFloat,
    max,
    min,
    onBlur,
    ...props
}: InputProps) => {
    const { internalValue, ref, handleChange, handleFocus, handleBlur } = useInput(
        type,
        defaultValue,
        onChange,
        acceptFloat,
        value,
        min,
        max,
        shouldSelect,
    );

    return (
        <input
            {...props}
            value={internalValue}
            className={twMerge(
                `focus:outline-irBlue-400 dark:focus:outline-none p-2 rounded-lg border-irGray-400 dark:border-darkSurface-100 border select-all h-12 bg-white dark:bg-darkSurface-200`,
                className,
            )}
            ref={ref}
            onChange={handleChange}
            max={max}
            min={min}
            onFocus={handleFocus}
            onBlur={(e) => {
                onBlur && onBlur(e);
                handleBlur();
            }}
        />
    );
};
