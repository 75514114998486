import { ReduxProvider } from "components/common/ReduxProvider/ReduxProvider";
import { ImageUploader } from "components/common/Uploader/ImageUploader/ImageUploader";
import { useRouter } from "next/router";
import { MAX_UPLOAD_LIMIT } from "../uploaderUtils";
import { Settings } from "./Settings/Settings";
import { useCompressUploader } from "./useCompressUploader";
import { getAnalytics, getHtmlFileInputId, getSupportedExtensions } from "./utils";

const CompressUploaderContent = () => {
    const { deleteImage, deleteAllImages } = useCompressUploader();
    const { pathname } = useRouter();


    return (
        <ImageUploader
            deleteImage={deleteImage}
            deleteAllImages={deleteAllImages}
            maxUploads={MAX_UPLOAD_LIMIT}
            analytics={getAnalytics(pathname)}
            settingsSlot={<Settings />}
            htmlFileInputId={getHtmlFileInputId(pathname)}
            supportedExtensions={getSupportedExtensions(pathname)}
        />
    );
};

export const CompressUploader = () => {
    return (
        <ReduxProvider>
            <CompressUploaderContent />
        </ReduxProvider>
    );
};
