import { useUploader } from "../useUploader";
import { setImages } from "store/features/compress/actions";
import { getImageExtension } from "client/utils/getImageExtension";
import { formatBytes } from "client/utils/formatBytes";
import { getRandomId } from "client/utils/getRandomId";
import { store, useAppDispatch } from "store/store";
import { ga } from "client/utils/adsense/ga";
import { gtag } from "client/utils/adsense/gtag";
import { useRouter } from "next/router";
import { getFCUrl } from "client/utils/getFCUrl";
import translatedRoutedSlugs from "translatedRouteSlugs";
import { toSnakeCase, toTitleCase } from "@/utils/reCase";

export const useCompressUploader = () => {
    const dispatch = useAppDispatch();
    const router = useRouter();
    const pathname = router.pathname;

    const handleProceed = () => {
        const task = images[0]?.task;
        if (!task) return;
        dispatch(
            setImages(
                images.map((image) => {
                    const ext = getImageExtension(image.file.file);
                    return {
                        id: getRandomId(),
                        original: {
                            height: 0,
                            width: 0,
                            size: formatBytes(image.task?.result?.size ?? 0),
                        },
                        preview: {
                            height: 0,
                            width: 0,
                            size: formatBytes(
                                image.task?.result?.size
                                    ? (image.task.result.size ?? image.file?.size ?? image.file?.file.size ?? 0)
                                    : 0,
                            ),
                        },
                        src: getFCUrl(
                            image.upload.type === "device"
                                ? image.file.src
                                : image.task?.result?.url
                                  ? (image.task?.result?.url.toString() ?? "")
                                  : "",
                        ),
                        title: image.file?.file.name ?? "",
                        format: image.file ? getImageExtension(image.file.file) : ".jpg",
                        createdAt: new Date(),
                        quality: store.getState().compress.compressionSettings.quality.value,
                        sliderValue: store.getState().compress.compressionSettings.quality.value,
                        importTask: image.task,
                        state: "imported",
                        hasExpired: false,
                        file: image.file,
                        uploadType: image.upload.type,
                        settings: image.upload.settings,
                        compressionSettings: {
                            ...store.getState().compress.compressionSettings,
                            type: ext === "png" ? "quality" : store.getState().compress.compressionSettings.type,
                        },
                        ext,
                    };
                }),
            ),
        );
        router.push((translatedRoutedSlugs as any)[`${pathname}/editor`]?.[router.locale ?? "en"] ?? `${pathname}/editor`);
    };
    const handleFirstImageLoadedAnalytics = () => {
        const eventCategory = toTitleCase(pathname);
        const keyName = toSnakeCase(pathname);
        ga("send", {
            hitType: "pageview",
            page: `/vp/${keyName}/image_loaded/`,
            title: `${eventCategory} Image Loaded`,
        });
        ///event
        gtag("event", "Image Loaded", {
            event_category: eventCategory,
            event_label: "Upload Page",
        });
    };

    const { images, deleteImage, deleteAllImages } = useUploader(handleProceed, handleFirstImageLoadedAnalytics);

    return { deleteImage, deleteAllImages };
};
