import { compressSlice } from "./slice";

export const {
    addImage,
    deleteImage,
    setSelectedImage,
    setQuality,
    setPreviewSize,
    deleteAllImages,
    setSliderValue,
    setSort,
    setImages,
    setHasExpired,
    setCompressed,
    importComplete,
    setImportTask,
    setProgress,
    setState,
    setError,
    setTooltipBounceImageId,
    imageCompressionSettingsSetMaxFileSize,
    imageCompressionSettingsSetQuality,
    imageCompressionSettingsSetType,
    setAllImagesCompressionSettings,
    settingsSetMaxFileSize,
    settingsSetQuality,
    settingsSetType,
    setDownloadButtonState
} = compressSlice.actions;
