import type { Height } from "client/types/Height";
import type { DetailedHTMLProps, InputHTMLAttributes, ReactNode } from "react";
import { ArrowIcon } from "../Icons/ArrowIcon";
import styles from "./slider.module.css";
import { useSlider } from "./useSlider";

export interface SliderProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    value?: number;
    defaultValue: number;
    min: number;
    max: number;
    handleChange: (value: number) => void;
    handleSlideEnd?: (value: number) => void;
    isDisabled?: boolean;
    shouldDebounce?: boolean;
    toolTip?: (value: number) => ReactNode;
    customStyles?: SliderStyles;
    testId?: string;
}

export interface SliderStyles {
    thumbStyles?: ThumbStyles;
    trackStyles?: TrackStyles;
    showIcon?: boolean;
}

interface ThumbStyles {
    width: number;
    height: number;
    border?: string;
}

interface TrackStyles {
    height: Height;
}

const defaultCustomStyles = {
    thumbStyles: {
        width: 20,
        height: 20,
        border: "3px solid white",
    },
    trackStyles: {
        height: "10px",
    },
    showIcon: false,
} as const;

export const Slider = ({
    min,
    max,
    defaultValue,
    handleChange,
    value,
    toolTip,
    isDisabled,
    step,
    customStyles,
    shouldDebounce,
    handleSlideEnd,
    testId,
}: SliderProps) => {
    customStyles = {
        ...defaultCustomStyles,
        ...customStyles,
    };
    const { onChange, ref, controlledValue, percentage, onTouchEnd, onMouseDown } = useSlider(
        max,
        min,
        defaultValue,
        handleChange,
        customStyles,
        !!shouldDebounce,
        !!isDisabled,
        value,
        handleSlideEnd,
    );

    return (
        <div className="flex flex-col gap-2">
            <div className={`${styles.slider} w-full rounded-lg gap-2`}>
                <div className="relative flex w-full h-full">
                    <input
                        className="touch-none"
                        type="range"
                        min={min}
                        max={max}
                        value={controlledValue}
                        onChange={onChange}
                        ref={ref}
                        disabled={!!isDisabled}
                        onKeyUp={() => handleSlideEnd?.(value ?? Math.round((max - min) / 2))}
                        onTouchEnd={onTouchEnd}
                        onMouseDown={onMouseDown}
                        data-testid={testId}
                        step={step}
                    />
                    <div
                        className="cursor-pointer absolute top-0 bottom-0 pointer-events-none flex items-center justify-center bg-transparent"
                        style={{
                            left: `clamp(0px, calc(${percentage}% - ${
                                (percentage *
                                    (customStyles.thumbStyles?.width || defaultCustomStyles.thumbStyles.width)) /
                                100
                            }px), calc(100% - ${
                                (percentage *
                                    (customStyles.thumbStyles?.width || defaultCustomStyles.thumbStyles.width)) /
                                100
                            }px))`,
                        }}
                    >
                        <div
                            className={`relative rounded-full ${
                                isDisabled ? "bg-irGray-600" : "bg-irBlue-200"
                            } cursor-pointer ring-[1px] flex items-center justify-center`}
                            style={{
                                // marginTop: customStyles.thumbStyles?.marginTop ?? "0px",
                                width: customStyles.thumbStyles?.width ?? "20px",
                                height: customStyles.thumbStyles?.height ?? "20px",
                                border: customStyles.thumbStyles?.border ?? "5px solid white",
                            }}
                        >
                            {!!customStyles.showIcon && (
                                <>
                                    <div className="rotate-90">
                                        <ArrowIcon className="stroke-white" />
                                    </div>
                                    <div className="-rotate-90">
                                        <ArrowIcon className="stroke-white" />
                                    </div>
                                </>
                            )}

                            {toolTip && toolTip(controlledValue)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
