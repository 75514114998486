import { useDispatch } from "react-redux";
import { ImageUpload, ImageUploaderState } from "store/features/imageUploader/types";
import {
    deleteImage as deleteImageAction,
    deleteAllImages as deleteAllImagesAction,
    reset,
} from "store/features/imageUploader/actions";
import { imageUploaderSelector } from "store/features/imageUploader/selectors/imageUploaderSelector";
import { useAppSelector } from "store/store";
import { useEffect, useRef } from "react";
import { isUploadComplete } from "./uploaderUtils";

export type DeleteImage = (image: ImageUpload) => void;

export const useUploader = (handleProceed: () => void, firstImageUploaded?: () => void) => {
    const { images } = useAppSelector(imageUploaderSelector, equalityFn);
    const dispatch = useDispatch();
    const isFirstImageUploadedCalled = useRef(false);

    const deleteImage: DeleteImage = (image) => {
        dispatch(deleteImageAction(image.id));
    };

    const deleteAllImages = () => {
        dispatch(deleteAllImagesAction());
    };

    useEffect(() => {
        if (images.length > 0 && !isFirstImageUploadedCalled.current) {
            firstImageUploaded && firstImageUploaded();
            isFirstImageUploadedCalled.current = true;
        }

        if (images.length > 0 && isUploadComplete(images)) {
            handleProceed();
            setTimeout(() => dispatch(reset()), 2000); // this delay is just for a good user experience
        }
    }, [images]);

    useEffect(() => {
        return () => {
            dispatch(reset());
        };
    }, []);

    return {
        images,
        deleteImage,
        deleteAllImages,
    };
};

const equalityFn = (left: ImageUploaderState, right: ImageUploaderState) => {
    if (left.images.length !== right.images.length) return false;

    let hasChanged = false;

    left.images.forEach((image) => {
        const rightImage = right.images.find((_image) => _image.id === image.id);
        if (!rightImage) {
            hasChanged = true;
            return;
        }

        if (image.fileState.status !== rightImage.fileState.status) {
            hasChanged = true;
        }
    });

    return !hasChanged;
};
