import { translateFunc } from "client/utils/translateFunc";
import { QuestionIcon } from "components/common/Icons/QuestionIcon";
import { Input } from "components/common/Input/Input";
import { Radio } from "components/common/Radio/Radio";
import { Slider } from "components/common/Slider/Slider";
import { ToolTip } from "components/common/ToolTip/ToolTip";
import type { CompressState } from "store/features/compress/types";

interface CompressionSettingsProps {
    isMaxFileSizeDisabled: boolean;
    shouldHideMaxFileSize?: boolean;
    isQualityDisabled: boolean;
    type: CompressState["compressionSettings"]["type"];
    maxFileSize: CompressState["compressionSettings"]["maxFileSize"];
    quality: CompressState["compressionSettings"]["quality"];
    handleQualityChange: (value: number) => void;
    handleTypeChange: (type: CompressState["compressionSettings"]["type"]) => void;
    handleMaxFileSizeChange: (type: number) => void;
    isProcessing?: boolean;
    handleApply?: () => void;
}

export const CompressionSettings = ({
    type,
    maxFileSize,
    isMaxFileSizeDisabled,
    shouldHideMaxFileSize=false,
    isQualityDisabled,
    quality,
    handleApply,
    handleTypeChange,
    handleQualityChange,
    handleMaxFileSizeChange,
    isProcessing,
}: CompressionSettingsProps) => {
    return (
        <div className="grid grid-cols-[auto_1fr] text-sm py-2 md:gap-8 dark:bg-darkSurface-200 text-black dark:text-white rounded-b-md">
            <div className="px-2 sm:px-4 border-b-black py-2 flex flex-col justify-center gap-6">
                {!shouldHideMaxFileSize && (
                    <div className="flex items-center gap-2">
                        <Radio
                            value={type === "max_file_size"}
                            label={translateFunc("max-file-size-label")}
                            handleClick={() => handleTypeChange("max_file_size")}
                            className={"font-semibold"}
                            isDisabled={isMaxFileSizeDisabled || isProcessing}
                        />
                        <ToolTip text={translateFunc("max-file-size-tooltip")}>
                            <QuestionIcon />
                        </ToolTip>
                    </div>
                )}
                <div className="flex items-center gap-2">
                    <Radio
                        value={type === "quality"}
                        label={translateFunc("quality-label")}
                        handleClick={() => handleTypeChange("quality")}
                        className="font-semibold"
                        isDisabled={isQualityDisabled || isProcessing}
                    />
                    <ToolTip text={translateFunc("quality-tooltip")}>
                        <QuestionIcon />
                    </ToolTip>
                </div>
            </div>
            <div className="flex items-center justify-center">
                {type === "max_file_size" && (
                    <Input
                        value={maxFileSize.value === 0 ? "" : maxFileSize.value}
                        type="number"
                        onChange={(e) => handleMaxFileSizeChange(+e.target.value)}
                        placeholder={translateFunc("enter-max-file-size")}
                        className={`border border-irGray-400 outline-0 w-full mr-2 sm:mr-4 dark:bg-darkSurface-100 text-xs sm:text-base`}
                        disabled={isProcessing}
                    />
                )}

                {type === "quality" && (
                    <div className="w-full mr-4">
                        <Slider
                            value={quality.value}
                            defaultValue={quality.value}
                            min={5}
                            max={100}
                            handleChange={(value) => handleQualityChange(value)}
                            handleSlideEnd={() => handleApply && handleApply()}
                            disabled={isProcessing}
                            toolTip={() => (
                                <div
                                    className={`absolute bottom-full mb-2 ${
                                        isQualityDisabled ? "bg-irGray-600" : "bg-black"
                                    } text-white py-2 px-8 rounded-lg`}
                                >
                                    {quality.value}%
                                    <div className="relative">
                                        <div
                                            className={`absolute top-full left-0 right-0 ${
                                                isQualityDisabled ? "bg-irGray-600" : "bg-black"
                                            }  w-3 h-3 rotate-45 ml-1.5`}
                                        ></div>
                                    </div>
                                </div>
                            )}
                            isDisabled={isQualityDisabled}
                            testId={`compressor-editor-slider`}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
