import { useState, useRef, type ChangeEvent, useEffect } from "react";
import { SliderStyles } from "./Slider";
import { useTheme } from "client/contexts/ThemeProvider";

let timeout: NodeJS.Timeout | null = null;

export const useSlider = (
    max: number,
    min: number,
    defaultValue: number,
    handleChange: (value: number) => void,
    customStyles: SliderStyles,
    shouldDebounce: boolean,
    isDisabled: boolean,
    value?: number,
    handleSlideEnd?: (value: number) => void,
) => {
    const [controlledValue, setControlledValue] = useState(defaultValue);
    const ref = useRef<HTMLInputElement>(null);
    const isMouseDownRef = useRef(false);
    const percentage = Math.abs(Math.floor(((controlledValue - min) / (max - min)) * 100));
    const { appliedTheme } = useTheme();
    const isDarkTheme = appliedTheme === "dark";

    useEffect(() => {
        if (typeof value !== "undefined") {
            setControlledValue(value);
        }
    }, [value]);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const number = +e.target.value;

        if (number < min || number > max) return;

        setControlledValue(number);

        if (shouldDebounce) {
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(() => handleChange(number), 500);
        } else {
            handleChange(number);
        }
    };

    function onMouseDown() {
        isMouseDownRef.current = true;
    }

    function onMouseUp() {
        if (!isMouseDownRef.current) return;
        handleSlideEnd && handleSlideEnd(Number(ref.current?.value || controlledValue));
        isMouseDownRef.current = false;
    }

    function onTouchEnd() {
        handleSlideEnd && handleSlideEnd(Number(ref.current?.value || controlledValue));
    }

    const setBackgroundColor = () => {
        if (ref.current) {
            const color = isDisabled ? "var(--darker-gray)" : "var(--blue)";
            ref.current.style.background = `linear-gradient(to right, ${color} 0%, ${color} ${percentage}%, ${
                isDarkTheme ? "var(--dark-surface-600)" : "#EEEDF1"
            } ${percentage}%, ${isDarkTheme ? "var(--dark-surface-600)" : "#EEEDF1"} 100%)`;
        }
    };

    useEffect(() => {
        setBackgroundColor();
    }, [controlledValue, isDisabled, isDarkTheme]);

    useEffect(() => {
        if (!ref.current) return;

        if (customStyles.thumbStyles) {
            ref.current.style.setProperty("--thumb-width", `${customStyles.thumbStyles.width}px`);
            ref.current.style.setProperty("--thumb-height", `${customStyles.thumbStyles.height}px`);
        }

        if (customStyles.trackStyles) {
            ref.current.style.setProperty("--track-height", customStyles.trackStyles.height);
        }
    }, [customStyles, ref]);

    function valueChangeEventHandler(e: CustomEvent<{ value?: number }>) {
        const value = e.detail.value;
        if (typeof value === "undefined" || value === controlledValue) return;
        setControlledValue(value);
        handleChange(value);
        handleSlideEnd && handleSlideEnd(value);
    }

    useEffect(() => {
        const el = ref.current;
        if (!el) return;
        el.addEventListener("valueChange", valueChangeEventHandler as EventListener);
        return () => {
            el.removeEventListener("valueChange", valueChangeEventHandler as EventListener);
        };
    }, [ref]);

    useEffect(() => {
        document.body.addEventListener("mouseup", onMouseUp);
        return () => {
            document.removeEventListener("mouseup", onMouseUp);
        };
    }, []);

    return {
        onChange,
        controlledValue,
        percentage,
        ref,
        onMouseDown,
        onMouseUp,
        onTouchEnd,
    };
};
